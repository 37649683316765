import classes from "./StatBlock.module.css";
import {
	stat_getAvgTime_entireCourse,
	stat_getAvgTime_knowledgeChecks,
	stat_getAvgTime_finalAssessment,
	stat_getAvgScore_finalAssessment,
} from "../../connections";
import { useEffect, useState } from "react";


function msToTime(duration: number): string {
    // Calculate hours, minutes, and seconds
    const milliseconds = Math.floor((duration % 1000) / 100);
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    // Format the values as two-digit strings
    const hoursStr = (hours < 10) ? "0" + hours : hours.toString();
    const minutesStr = (minutes < 10) ? "0" + minutes : minutes.toString();
    const secondsStr = (seconds < 10) ? "0" + seconds : seconds.toString();

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

const StatBlock = (Props: { statName: string; title?: string; className?: string }) => {
	const [stat, setStat] = useState<string>("Temp");
	const getStatData = async () => {
		let data;
		let returnValue;
		
		switch (Props.statName) {
			case "Entire Course":
				data = await stat_getAvgTime_entireCourse();
				returnValue = msToTime(data.data[0].average_course_time);
				console.log(returnValue);
				setStat(returnValue);
				break;
			case "Knowledge Checks":
				data = await stat_getAvgTime_knowledgeChecks();
				returnValue = msToTime(data.data[0].average_final_assessment_time);
				console.log(returnValue);
				setStat(returnValue);
				break;
			case "Final Assessment":
				data = await stat_getAvgTime_finalAssessment();
				returnValue = msToTime(data.data[0].average_final_assessment_time);
				console.log(returnValue);
				setStat(returnValue);
				break;
			case "*":
				data = await stat_getAvgScore_finalAssessment();
				returnValue = data.data[0].average_score;
				console.log(returnValue);
				setStat(returnValue);
				break;
		}
	};
	useEffect(() => {
		getStatData();
	}, []);

	return (
		<div className={`${classes.statContainer} ${Props.className}`}>
			{Props.title && <p className={classes.title}>{Props.title}</p>}
			<div className={classes.innerContainer}>
				{Props.statName.length > 1 && <p className={classes.name}>{Props.statName}</p>}
				<p className={classes.stat}>{[stat]}</p>
			</div>
		</div>
	);
};

export default StatBlock;
