import React, { useState, useEffect, ReactNode } from 'react';

interface AuthWrapperProps {
  children: ReactNode;
}

interface AuthResponse {
  authenticated?: boolean;
  error?: string;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {

    const checkAuthStatus = async (): Promise<void> => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const id_course = urlParams.get('id_course');

        // Instead of hitting the root path, use a dedicated auth check endpoint
        const response = await fetch(`/auth/status?id_course=${id_course}`, {
          credentials: 'include'
        });


        // If we got redirected, follow the redirect
        if (response.redirected) {
          window.location.href = response.url;
          return;
        }

        if (response.status === 401) {
          // If unauthorized, redirect to login
          const currentUrl = `${window.location.pathname}${window.location.search}`;
          window.location.href = `/auth/login?returnUrl=${encodeURIComponent(currentUrl)}`;          
          return;
        }

        const data = await response.json();

        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setError(data.error || 'An error occurred while checking authorization.');
        }
      } catch (err) {
        setError('Network error occurred while checking authorization.');
      } finally {
        setIsLoading(false);
      }
    };

  checkAuthStatus();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4">
        <div className="bg-red-50 border border-red-200 text-red-800 rounded-lg p-4">
          <h3 className="text-lg font-semibold">Authentication Error</h3>
          <p className="mt-1 text-sm">{error}</p>
        </div>
      </div>
    );
  }

  return isAuthenticated ? <>{children}</> : null;
};

export default AuthWrapper;