// Helper to extract courseId from the URL
const getCourseIdFromURL = (): string | null => {
	const params = new URLSearchParams(window.location.search);
	const courseId = params.get('id_course'); // Extract 'id_course' from query parameters
  
	if (!courseId) {
	  console.warn("courseId not found in the URL.");
	}
  
	console.log(`Extracted courseId from URL: ${courseId}`);
	return courseId;
  };
  
  // Helper function for API requests
  const fetchData = async (endpoint: string, courseId?: string) => {
	try {
	  const id = courseId || getCourseIdFromURL();
	  if (!id) throw new Error("No courseId available for the API request.");
  
	  const url = `${window.location.origin}/${endpoint}?id_course=${id}`;
	  console.log(`Fetching from: ${url}`);
  
	  const response = await fetch(url, { mode: 'no-cors' });  // Add 'no-cors'
	  
	  if (!response.ok) throw new Error(`Error ${response.status}: ${response.statusText}`);
	  
	  return await response.json();
	} catch (error) {
	  console.error("Fetch error:", error);
	  throw error;
	}
  };  
  
  // Stat Block Queries
  export const stat_getAvgTime_entireCourse = () => fetchData('entireCourse');
  export const stat_getAvgTime_knowledgeChecks = () => fetchData('knowledgeCheckAverageScore');
  export const stat_getAvgTime_finalAssessment = () => fetchData('assessmentTime');
  export const stat_getAvgScore_finalAssessment = () => fetchData('assessmentScore');
  
  // Table Data Queries
  export const table_getPage_completionsPerLearnerData = () => fetchData('table');
  export const table_getModuleData = () => fetchData('module');
  export const table_getInteractionsData = () => fetchData('interactions');
  export const table_getInternalLinksData = () => fetchData('internal');
  
  // Chart Data Queries
  export const chart_getLearnersByStatus = () => fetchData('learnersInProgress');
  export const chart_getFinalAssessmentAttempts = () => fetchData('final');
  export const chart_getFinalAssessmentCompletedCount = () => fetchData('learnersCompleted');
  export const chart_learnersCompletedCount = () => fetchData('learnersCompletedCount');
  export const chart_learnersInProgressCount = () => fetchData('learnersInProgressCount');
  export const chart_definedInteractionsALL = () => fetchData('definedInteractionsALL');
  export const chart_learnerAll = () => fetchData('all');
  