// Import styles if needed
import "./App.css";
import Chart from "./components/Chart/Chart";
import Table from "./components/Table/Table";
import StatBlock from "./components/StatBlock/StatBlock";
import AnalyticsHeader from "./components/AnalyticsHeader";
import ChartTotalLearners, {
	studentLayout,
	attemptLayout,
	studentData,
	attemptData,
  } from './components/ChartTotalLearners/ChartTotalLearners';

const AnalyticsTable = () => {
  
  return (
    <div className="App">
			<AnalyticsHeader />
			<div className="headerBar">
				<img src="jnj.png" alt="" />
			</div>
			<div className="grid">
				<div className="row1">
				<ChartTotalLearners graphName="StudentGraph" layout={studentLayout} data={studentData} />
					{/* <Chart graphName="StudentGraph" layout={studentLayout} data={studentData} /> */}
					<Table tableName="completions" />
					{/* Moved from Right side to see how it looks VVV */}
					<Table tableName="interactions" />
				</div>
				<div className="row2">
					<div className="statGroup">
						<StatBlock title="Average Time to Completion" statName="Entire Course" />
						<StatBlock statName="Knowledge Checks" />
						<StatBlock statName="Final Assessment" />
					</div>
					<StatBlock className="statBottom" title="Avg Score - Final Assessment" statName="*" />
				</div>
				<div className="row3">
					<Table tableName="module" />
					<Table tableName="internal" />
					<ChartTotalLearners graphName="AttemptsGraph" layout={attemptLayout} data={attemptData} />
				</div>
			</div>
		</div>
  );
};

export default AnalyticsTable;
