

const AnalyticsHeader = () => {
    return (
        <div className="header">
            <h1>Analytics Header</h1>
        </div>
    );

}


export default AnalyticsHeader;